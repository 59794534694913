import 'bootstrap';

import 'bootstrap/dist/css/bootstrap.min.css';
import 'lightbox2/dist/css/lightbox.min.css';

/* <<< fixes the modals on the frontend */
import 'semantic-ui-css/components/dimmer.css';
import 'semantic-ui-css/components/modal.css';
import 'semantic-ui-css/components/transition.css';
/* >>> fixes the modals on the frontend */

import './sass/styles.scss';

import './js/address-book-dropdown';
import './js/address-login';
import './js/calculator';
import './js/mobile-menu';
import './js/addToCartCalculator';
import './js/GTM/cart-item-remove';
