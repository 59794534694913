import Vue from 'vue';

// eslint-disable-next-line no-new
new Vue({
  el: '.js-menu',
  delimiters: ['${', '}'],
  data: {
    activeMenu: false,
  },
});
