export default class FlexWipesCalculator {
  constructor(maxQuantity, ltlQuantities) {
    this.maxQuantity = maxQuantity;
    // Filter the LTL values that are lower than the last regular value
    this.ltlQuantities = ltlQuantities.filter((el) => el > maxQuantity);
    // Add the maxQuantity back to be able to jump to it from higher LTL numbers when going down
    this.ltlQuantities.unshift(maxQuantity);
  }

  isValid(quantity) {
    return quantity <= this.maxQuantity || this.ltlQuantities.indexOf(quantity) !== -1;
  }

  increase(quantity) {
    if (this.maxQuantity === null || quantity < this.maxQuantity) {
      return quantity + 1;
    }
    // find the next LTL quantity
    return this.ltlQuantities.filter((el) => el > quantity)[0];
  }

  decrease(quantity) {
    if (this.maxQuantity !== null && quantity > this.maxQuantity) {
      // find the previous LTL quantity
      return this.ltlQuantities.filter((el) => el < quantity).reverse()[0];
    }

    if (quantity > 1) {
      return quantity - 1;
    }

    return 0;
  }
}
