import jQuery from 'jquery';

(($) => {
  const target = $('#sylius-api-login-form');
  const observer = new MutationObserver((() => {
    if (target.is(':visible')) {
      $('.sylius-shipping-non-login-form').hide();
    } else {
      $('.sylius-shipping-non-login-form').show();
    }
  }));
  if (target.length) {
    observer.observe(target[0], {
      attributes: true,
    });
  }
})(jQuery);
