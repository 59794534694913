import jQuery from 'jquery';

(($) => {
  function parseKey(key) {
    return key.replace(/(_\w)/g, (words) => words[1].toUpperCase());
  }
  function findByName(name, address) {
    return address.find(`[name*=${parseKey(name)}]`);
  }

  $('.address-book-dropdown').on('click', '.dropdown-item', (ev) => {
    const choice = $(ev.currentTarget);
    const address = choice.closest('.sylius-address');
    const { provinceCode, provinceName } = choice.data();
    const provinceContainer = address.find('.province-container').get(0);

    $.each(choice.data(), (property, value) => {
      const field = findByName(property, address);

      if (-1 !== property.indexOf('countryCode')) {
        field.val(value).change();

        const exists = setInterval(() => {
          const provinceCodeField = findByName('provinceCode', address);
          const provinceNameField = findByName('provinceName', address);

          if (!provinceContainer.hasAttribute('data-loading')) {
            if (0 !== provinceCodeField.length && ('' !== provinceCode || undefined !== provinceCode)) {
              provinceCodeField.val(provinceCode);

              clearInterval(exists);
            } else if (0 !== provinceNameField.length && ('' !== provinceName || undefined !== provinceName)) {
              provinceNameField.val(provinceName);

              clearInterval(exists);
            }
          }
        }, 100);
      } else {
        field.val(value);
      }
    });
  });
})(jQuery);
