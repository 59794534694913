import jQuery from 'jquery';

jQuery(document).ready(() => {
  jQuery('.sylius-cart-remove-form').submit((event) => {
    const $form = jQuery(event.currentTarget);

    const obj = {
      name: $form.data('name'),
      id: $form.data('id').toString(),
    };

    const price = $form.data('price');
    if (typeof price !== 'undefined') {
      obj.price = price;
    }

    const brand = $form.data('brand');
    if (typeof brand !== 'undefined') {
      obj.brand = brand;
    }

    const category = $form.data('category');
    if (typeof category !== 'undefined') {
      obj.category = category;
    }

    const variant = $form.data('variant');
    if (typeof variant !== 'undefined') {
      obj.variant = variant;
    }

    const quantity = $form.data('quantity');
    if (typeof quantity !== 'undefined') {
      obj.quantity = quantity;
    }
  });
});
