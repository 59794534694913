import Vue from 'vue';
import { debounce } from 'lodash';
import FlexWipesCalculator from './calculator';

window.initCalculatorForAddToCart = function initCalculatorForAddToCart(isLoading, url, defaultData) {
  const data = {
    errors: {
      zip: false,
      count: false,
    },
    calculator: null,
    previousRequest: null,
    isLoading: true,
    quantity: 1,
    ...defaultData,
  };

  return new Vue({
    el: '.js-calculator',
    delimiters: ['!{', '}!'],
    data,
    watch: {
      zipCode: function zipCode() {
        this.calculate();
      },
    },
    created() {
      this.doCalculate = debounce(this.doCalculate, 300);
      this.calculator = new FlexWipesCalculator(this.maxQuantity, this.ltlQuantities);

      if ('' !== this.zipCode) {
        this.isLoading = isLoading;
      }
    },
    methods: {
      increase() {
        const quantity = this.calculator.increase(this.quantity);
        this.setQuantity(quantity);
      },
      decrease() {
        const quantity = this.calculator.decrease(this.quantity);
        this.setQuantity(quantity);
      },
      setQuantity(quantity) {
        if (quantity) {
          this.reset();
          this.quantity = quantity;
          if (window.gtmAddToCartProductInfo) {
            window.gtmAddToCartProductInfo.quantity = quantity;
          }
          this.doCalculate();
        }
      },
      calculate() {
        this.reset();
        this.doCalculate();
      },
      doCalculate() {
        this.previousRequest = new AbortController();
        fetch(`${url}?count=${this.quantity}&zip=${this.zipCode}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
          // You can use the "before" logic in the "fetch" method using "signal" and "AbortController"
          signal: this.previousRequest.signal,
        })
          .then((response) => response.json())
          .then((price) => {
            this.price = price;
            this.previousRequest = null;
            this.isLoading = false;
          })
          .catch((error) => {
            if (error instanceof Response) {
              error.then((errorBody) => {
                if (errorBody && errorBody.errors) {
                  Object.keys(errorBody.errors).forEach((field) => {
                    if (null != this.errors[field]) {
                      this.errors[field] = true;
                    }
                  });
                }
              });
            }
          });
      },
      reset() {
        Object
          .keys(this.errors)
          .forEach((field) => { this.errors[field] = false; })
        ;

        // abort previous request, if exists
        if (this.previousRequest) {
          this.previousRequest.abort();
        }

        this.isLoading = true;
      },
    },
  });
};
